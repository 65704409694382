import { MergeConfiguration } from "@redrow/configuration";
import { CONFIGURATION_BASE } from "./config.base";
import { CONFIGURATION_ENVIRONMENT } from "./config.local";
import { ExternalLoginAppConfigurationBase, ExternalLoginAppConfigurationEnvironment, IExternalLoginAppConfig } from "./external-login-app.interface";

/**
 * Factory function provided to the configuration system which will then combine all configurations based on the environment.
 * 
 * Although we are importing the local config - this will be replaced in angular.json to the appropriate environment configuration
 */
export const applicationConfigurationFactory: () => MergeConfiguration<IExternalLoginAppConfig, ExternalLoginAppConfigurationBase, ExternalLoginAppConfigurationEnvironment> = () => {
    return [CONFIGURATION_BASE, CONFIGURATION_ENVIRONMENT];
}
