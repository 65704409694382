import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";



const routes: Routes = [

	/**
	 * Route for setting up a new account
	 */
	{
		path: "accountsetup",
		loadChildren: () => import("./route/accountsetup/accountsetup.module").then(m => m.AccountSetupModule)
	},

	/**
	 * Route for logging in
	 */
	{
		path: "login",
		loadChildren: () => import("./route/login/login.module").then(m => m.LoginModule)
	},

	/**
	 * Route for resetting your password
	 */
	{
		path: "passwordreset",
		loadChildren: () => import("./route/passwordreset/passwordreset.module").then(m => m.PasswordResetModule)
	},

	/**
	 * Route for changing your password
	 */
	{
		path: "changepassword",
		loadChildren: () => import("./route/changepassword/changepassword.module").then(m => m.ChangePasswordModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
