import { RedrowEnvironmentType } from "@redrow/utilities";
import { ExternalLoginAppConfigurationBase } from "./external-login-app.interface";
import buildSettings from "../../build-settings.json";

const THIS_ENVIRONMENT: RedrowEnvironmentType = "Local";

// Left as baseConfig for a reason!
export const CONFIGURATION_BASE: ExternalLoginAppConfigurationBase = {
	LOCAL_VERSION: `${buildSettings.pipelineBuildNumber} ${buildSettings.pipelineBuildName}`,
	env: THIS_ENVIRONMENT,
	Version: "1.01",
	Revision: "a",
	GoogleRecaptchaSiteKey: "6LfE5M8ZAAAAAPcWjIdkwZXA_7a5A6mWSoidnYTJ",
	appId: "oauth-ext"
};
