import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ConfigurationModule } from "@redrow/configuration";
import { LoggerModule } from "@redrow/logging";
import { IconRegistryModule } from "@shared/modules";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ExternalLoginAppConfigurationBase, ExternalLoginAppConfigurationEnvironment, IExternalLoginAppConfig } from "@config/external-login-app.interface";
import { applicationConfigurationFactory } from "@config/config";

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [

		BrowserAnimationsModule,

		ReactiveFormsModule,

		HttpClientModule,


		/**
			 * Disable initial navigation so we can wait for the application to load first
			 */
		/**
	 * Disable initial navigation so we can wait for the application to load first
	 */
		RouterModule.forRoot([
			// TODO
		], {}),

		// @redrow/logging
		LoggerModule.default(),

		// @redrow/configuration
		ConfigurationModule.defaultWithConfigurationFactory<
				IExternalLoginAppConfig, 
				ExternalLoginAppConfigurationBase, 
				ExternalLoginAppConfigurationEnvironment
			>(applicationConfigurationFactory),

		/**
			 * Exports required infrastructure for all Angular apps. Included by defaults
			 * in all Angular apps created with the CLI new command. Re-exports CommonModule
			 * and ApplicationModule, making their exports and providers available to all apps.
			 */
		BrowserModule,

		/**
		 * Exports all the basic Angulardirectives and pipes, such as NgIf, NgForOf,
		 * DecimalPipe, and so on. Re-exported by BrowserModule, which is included
		 * automatically in the root AppModule when you create a new app with the
		 * CLI new command.
		 */
		CommonModule,

		AppRoutingModule,

		IconRegistryModule.forRoot()

	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
